import React from 'react';
import { graphql } from 'gatsby';
import styled from 'styled-components';
import { prop } from 'styled-tools';
import { TitleAndParagraph } from '@allthingswww/client-act-shared';
import Link from '../components/Link';
import Layout from '../components/Layout';

const StyledContainer = styled.div`
  width: 95%;
  margin: 0 auto;
  @media screen and (min-width: ${prop('theme.screens.large', '1280px')}) {
    width: 80%;
  }
  @media screen and (min-width: ${prop('theme.screens.xlarge', '2100px')}) {
    width: 60%;
  }
`;

const StyledBoardOfDirectorsSection = styled.div`
  & > div {
    width: 25rem;
    padding-right: 2rem;
    padding-bottom: 2rem;
    & > p:first-child {
    font-weight: 700;
    color: ${prop('theme.color.lightBlue', '#2B6191')};
    }
    & > p{
      &> p:first-child {
        font-weight: 700;
      }
      &> p:nth-child(2) {
        font-weight: 700;
      }
      & > a {
        color: ${prop('theme.color.blue', '#0F0F3A')};
        text-decoration: underline;
      }
    }
  }
  display: flex;
  flex-flow: row wrap;
`;

const StyledRow = styled.div` 
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
  & > p {
    width: 15rem;
  }
  & > p:first-child {
    font-weight: 700;
    color: ${prop('theme.color.lightBlue', '#2B6191')};
  }
  & > p:last-child {
    & > a {
    color: ${prop('theme.color.blue', '#0F0F3A')};
    text-decoration: underline;
    }
    & > p:first-child {
      font-weight: bold;
    }
  }
  padding-bottom: 1.5rem;
`;

const Row = (
  { item },
) => (
  <StyledRow>
    <p>
      {item.name}
    </p>
    <p>
      {item.role}
    </p>
    <p>
      {item.companyName && <p>{item.companyName}</p>}
      <Link to={`mailto:${item.email}`}>{item.email}</Link>
      {item.phone && (
      <p>
        Phone:
        {' '}
        <Link to={`tel:${item.phone}`}>{item.phone}</Link>
      </p>
      )}
      {item.fax && (
      <p>
        Fax:
        {' '}
        <Link to={`tel:${item.fax}`}>{item.fax}</Link>
      </p>
      )}
    </p>
  </StyledRow>
);

export const StaffPageTemplate = ({ data }) => {
  const { frontmatter } = data.page;
  const {
    title, subtitle, featuredImage, staffMembers, boardOfOfficers, boardOfDirectors,
  } = frontmatter;
  const staffMembersParsed = JSON.parse(staffMembers);
  const boardOfOfficersParsed = JSON.parse(boardOfOfficers);
  const boardOfDirectorsParsed = JSON.parse(boardOfDirectors);

  return (
    <Layout
      title={title}
      subtitle={subtitle}
      headerBackGroundImage={featuredImage}
      condensed
    >
      <StyledContainer>
        <TitleAndParagraph title="General Inquiries">
          ACT General Mailbox:
          {' '}
          <Link to="mailto:subs@actaz.net">subs@actaz.net</Link>
        </TitleAndParagraph>
        <TitleAndParagraph title="Staff">
          {staffMembersParsed.map((staffMember) => (
            <Row
              item={staffMember}
              key={staffMember.id}
            />
          ))}
        </TitleAndParagraph>
        <TitleAndParagraph title="Board Officers">
          {boardOfOfficersParsed.map((boardMember) => (
            <Row
              item={boardMember}
              key={boardMember.id}
            />
          ))}
        </TitleAndParagraph>
        <TitleAndParagraph title="Board of Directors">
          <StyledBoardOfDirectorsSection>
            {boardOfDirectorsParsed.map((boardMember) => (
              <div>
                <p>
                  {boardMember.name}
                </p>
                <p>
                  {boardMember.companyName && (<p>{boardMember.companyName}</p>)}
                  <Link to={`mailto:${boardMember.email}`}>{boardMember.email}</Link>
                  {boardMember.phone && (
                  <p>
                    Phone:
                    {' '}
                    <Link to={`tel:${boardMember.phone}`}>{boardMember.phone}</Link>
                  </p>
                  )}
                  {boardMember.fax && (
                  <p>
                    Fax:
                    {' '}
                    <Link to={`tel:${boardMember.fax}`}>{boardMember.fax}</Link>
                  </p>
                  )}
                </p>
              </div>
            ))}
          </StyledBoardOfDirectorsSection>
        </TitleAndParagraph>
      </StyledContainer>
    </Layout>
  );
};

export default StaffPageTemplate;

export const pageQuery = graphql`
  query StaffPageMdx($id: String!) {
    page: mdx(id: { eq: $id }) {
      body
      frontmatter {
        title
        subtitle
        featuredImage
        staffMembers
        boardOfOfficers
        boardOfDirectors
      }
    }
  }
`;
